import VueCookies from 'vue-cookies'

// setToken
export const setCookies = (key, val, cookieExpires) => {
    VueCookies.set(key, val, { expires: cookieExpires || 1 })
}

// 获取getCookies
export const getCookies = (key) => {
    return VueCookies.get(key)
}

export const removeCookies = (key) => {
    return VueCookies.remove(key)
}